import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ListBtn, MaxWidth, ViewSection} from "./style";
import {Link, useNavigate} from "react-router-dom";
import axios from "axios";
import localDateTimeConverter from "../../hooks/useLocalDateTimeConverter";
import useApi from "../../hooks/useApi";
import {Loding} from "../style";

const View = () => {
    const [newsTit, setNewsTit] = useState("뉴스");
    const navigate = useNavigate();
    const path = window.location.pathname;
    const pathSplit = window.location.pathname.split("/");
    const [url, setUrl] = useState(`https://apis.kiwontech.com/board/${pathSplit[2]}/${pathSplit[3]}`);

    const data = useApi(url);
    const { apiData, isValidating, mutate } = data;
    const [view, setView] = useState([]);

    useEffect(() => {
        switch (pathSplit[2]){
            case "news" :
                setNewsTit("뉴스")
                break;
            case "security-issue" :
                setNewsTit("보안이슈")
                break;
            case "news-letter" :
                setNewsTit("뉴스레터")
                break;
            case "ir" :
                setNewsTit("IR")
                break;
            default:
                setNewsTit("뉴스")
        }
        setUrl(`https://apis.kiwontech.com/board/${pathSplit[2]}/${pathSplit[3]}`);
    },[path,window.location.search]);


    return (
        <ViewSection>
                {
                    isValidating || !apiData ? <div style={{height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}><span style={{fontSize:"20px"}}>Loading...</span></div> : (
                        <MaxWidth>
                            <h2>{newsTit}</h2>
                            <div className={`viewTop ${pathSplit[2] === 'ir' && apiData?.obj?.board?.files.length !== 0? 'mb-n' : ''}`}>
                                <h3>{apiData?.obj?.board?.title}</h3>
                                <span>
                                    {pathSplit[2] === "ir" ? localDateTimeConverter(apiData?.obj?.board?.regDate) : localDateTimeConverter(apiData?.obj?.board?.uptde || apiData?.obj?.board?.rgsde)}
                                </span>
                            </div>
                            {
                                pathSplit[2] === "ir" && apiData?.obj?.board?.files.length !== 0?
                                  <div className="fileAttach">
                                    <div className="title">첨부파일</div>
                                    <div className="fileList">
                                        {
                                            apiData?.obj?.board?.files?.map(item => (
                                              <div className="fileItem">
                                                  <div key={item.idx} className="fileName">
                                                      {item?.fileName}
                                                  </div>
                                                  <Link to={`https://apis.kiwontech.com/file/download/${item.idx}`}>
                                                      <div className="fileUrl"></div>
                                                  </Link>
                                              </div>
                                            ))
                                        }
                                    </div>
                                  </div> : ''
                            }
                            <div className="content" dangerouslySetInnerHTML={{__html: apiData?.obj?.board?.contents}}>

                            </div>
                            <div className="listLink">
                                <Link to={`/news/${pathSplit[2]}/${apiData?.obj?.nextBoard?.idx}`} className={apiData?.obj?.nextBoard ? "" : "disabled"}>
                                    <span>다음글</span>
                                    <span>{apiData?.obj?.nextBoard ? apiData?.obj?.nextBoard?.title : "다음글이 없습니다."}</span>
                                </Link>
                                <Link to={`/news/${pathSplit[2]}/${apiData?.obj?.prevBoard?.idx}`} className={apiData?.obj?.prevBoard ? "" : "disabled"}>
                                    <span>이전글</span>
                                    <span>{apiData?.obj?.prevBoard ? apiData?.obj?.prevBoard?.title : "이전글이 없습니다."}</span>
                                </Link>
                            </div>
                            <ListBtn type="button" title="리스트페이지 이동" onClick={() => navigate(`/news/${pathSplit[2]}`)}>LIST</ListBtn>
                        </MaxWidth>
                    )
                }

        </ViewSection>
    );
};

export default View;